<template>
  <b-card-code
    title="Teachers"
    no-body
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
    <b-col
      md="12"
      class="my-1"
    >
      <b-button    
        variant="primary"
        class="mb-1 float-right"
        to="/create-teacher"
      >
            Create Teacher
      </b-button>
    </b-col>
    <b-table
      :fields="fields"
      :items="teacherLists"
      responsive
      class="mb-0"
    >

      <!-- A virtual column -->
      <template #cell(Sr#)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(name)="data">
        <span class="text-nowrap">{{ data.value }}</span>
      </template>

      <template #cell(id)="data">
         
        <div class="text-nowrap">
            <b-button variant="outline-primary" :to="'/teacher/'+data.value+'/edit'" size="sm"><b-icon icon="pencil-square"></b-icon></b-button>
            <b-button class="ml-1" @click="deleteTeacher(data.value)" variant="outline-danger" size="sm"><b-icon icon="archive-fill"></b-icon></b-button>
            <b-button class="ml-1" variant="outline-info" :to="'/teacher/'+data.value+'/detail'" size="sm"><b-icon icon="eye-fill"></b-icon></b-button>
            
        </div>   
     </template>
    </b-table>
    <!-- pagination -->
    
        <b-col>
      
      
    <record-count :to ='toRecordCount' :from='fromRecordCount' :total='totalRecordCount'></record-count>
    <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :base-url="path"
        :per-page="perPageRecord"
        :align="selected"
        @input = "changePage(currentPage)"
        class = "mb-4"
      />
    </b-col>
      
    </b-overlay>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BProgress,BButton, BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BRow , BCol, BLink, BIcon} from 'bootstrap-vue'
import TeacherService from '@/services/teacher.service.js';
import UserService from '@/services/user.service.js';

export default {
  components: {
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BLink,
    BIcon
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'Sr#',
        // A column that needs custom formatting
        { key: 'name', label: 'Username' },
        // A regular column
        { key: 'email', label: 'Email' },
        // A regular column
        { key: 'created_at', label: 'Created At' },
        { key: 'id', label: 'Action' },
      ],
      teacherLists: [],
      marginePage: 10,
      initialPage: 0,
      pageRange: 10,
      pageCount: 0,
      currentPage: 0,
      srCounter: 0,
      perPageRecord: 0,        
      toRecordCount: 0,
      fromRecordCount: 0,
      totalRecordCount: 0,
      selected: 'right',
      currentPage: 1,
      rows: 0,
      path: '',
      showOverlay: false,
    }
  },
  methods: {
    getTeachers() {
                this.showOverlay = true;
                TeacherService.listing(
                    this.currentPage
                    ).then(response => {
                    this.teacherLists = response.data.data.teachers.data;
                    this.rows = response.data.data.teachers.total;
                    this.currentPage = response.data.data.teachers.current_page
                    this.path = response.data.data.teachers.path

                    this.pageCount = response.data.data.teachers.last_page;
                    this.currentPage = response.data.data.teachers.current_page;
                    this.perPageRecord = response.data.data.teachers.per_page;
                    this.toRecordCount = response.data.data.teachers.to;
                    this.fromRecordCount = response.data.data.teachers.from;
                    this.totalRecordCount = response.data.data.teachers.total;
                    this.srCounter = (this.currentPage-parseInt(1)) * this.perPageRecord;
                    this.showOverlay = false;
                }).catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
    },
    changePage(event) {
      this.getStudents(this.currentPage);
    },
    deleteTeacher(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showOverlay = true;
          UserService.delete(id).then(response => {
                    this.$swal({
                      icon: 'success',
                      title: 'Deleted!',
                      text: 'User has been deleted.',
                      customClass: {
                        confirmButton: 'btn btn-success',
                      },
                    })
                    const userIndex = this.teacherLists.findIndex(user => user.id === id);
                    this.teacherLists.splice(userIndex, 1);   
                    this.showOverlay = false;
                }).catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
          
        }
      })
    }
  },
  created() {
    this.getTeachers(this.currentPage);
  }
}
</script>
